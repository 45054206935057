@import "../../../../assets/scss/index";

.n-insights {
  margin-bottom: 70px;

  @include respond("md") {
    margin-bottom: 50px;
  }

  @include respond("sm") {
    margin-bottom: 30px;
  }
}
