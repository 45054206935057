@import "../../assets/scss/index";

.blog-article {
  @include respond("md") {
    &__img-wrap {
      margin-bottom: 12px;
    }

    &__title {
      margin-bottom: 14px;

      font-size: 18px;
    }

    &__tags {
      margin-bottom: 18px;

      & .tags-list__item {
        position: relative;
        padding: 6px 16px;
      }

      & .tags-list__link {
        font-size: 12px;
      }
    }
  }
}
