@import "../../../../assets/scss/index";

.n-skilled-team {
  width: 100%;
  position: relative;
  min-height: 600px;
  padding-top: 96px;

  &-line {
    position: absolute;
    z-index: -1;
    top: -80px;
    left: 33px;
  }

  .skilled-team {
    margin-bottom: 110px;

    @include respond("sm") {
      margin-bottom: 40px;
    }

    h2 {
      font-size: 40px;
      font-weight: 800;
      line-height: 56px;
      letter-spacing: 1px;
      margin-bottom: 48px;

      @include respond("sm") {
        font-size: 32px;
        line-height: 40px;
        text-align: center;
      }
    }

    &-button {
      position: absolute;
      left: 39%;

      @include respond("md") {
        left: 33%;
      }

      @include respond("sm") {
        left: 13%;
      }
    }

    .steps {
      display: flex;
      margin-bottom: 38px;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-start;

      @include respond("sm") {
        justify-content: center;
        margin-bottom: 0;
      }

      > img {
        margin-right: 60px;
      }

      .steps-wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        @include respond("lg") {
          flex-wrap: wrap;
        }

        @include respond("sm") {
          justify-content: center;
        }
      }

      .seven-days {
        width: 240px;
      }

      &-step {
        max-width: 305px;
        padding-right: 40px;

        @include respond("lg") {
          max-width: 50%;
          margin-bottom: 24px;
        }

        @include respond("sm") {
          max-width: 100%;
          padding: 0;

          &:last-child {
            margin-bottom: 0;
          }
        }

        .step-block {
          display: flex;
          margin: 15px 0px;
        }

        .block-one {
          display: flex;
          align-items: center;

          @include respond("lg") {
            > img {
              width: 80px;
            }
          }

          img {
            padding-right: 16px;
          }
        }

        .block-two {
          @include respond("lg") {
            > img {
              width: 80px;
            }
          }

          img {
            padding-right: 16px;
          }

          h3 {
            font-size: 32px;
            font-weight: 600;
            line-height: 56px;
            letter-spacing: 0.5px;
            color: #0b1524;
          }
        }

        p {
          font-size: 16px;
          line-height: 32px;
        }
      }
    }
  }

  .project-cost {
    width: 100%;
    display: flex;
    background: #ebf1fb;
    border-radius: 20px;
    padding: 0 15px;

    &-content {
      padding: 42px 60px 42px 30px;
      flex-grow: 1;

      h2 {
        margin-bottom: 20px;

        font-size: 32px;
        font-weight: 800;
        line-height: 48px;
        letter-spacing: 1px;

        @include respond("sm") {
          margin-bottom: 10px;

          font-size: 20px;
          line-height: 30px;
        }
      }

      p {
        margin-bottom: 30px;

        font-size: 18px;
        font-weight: 400;
        line-height: 24px;

        @include respond("sm") {
          margin-bottom: 15px;

          font-size: 14px;
          line-height: 18px;
        }
      }
    }

    &-button {
      height: 40px;
      width: 210px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 11px 0;

      @media screen and (max-width: 550px) {
        width: 180px;
      }
    }

    .image-torus {
      position: relative;
      left: -5%;
      margin-top: -35px;

      @media screen and (max-width: 1110px) {
        left: -3%;
      }
    }

    .image-cheese {
      position: relative;
      left: -9%;
      margin-top: 127px;

      img {
        transform: translateY(7px);
      }

      @media screen and (max-width: 1077px) {
        margin-top: 197px;

        img {
          transform: translateY(8px);
        }
      }

      @media screen and (max-width: 860px) {
        display: none;
      }
    }

    .image-torus-half {
      width: 80px;
      height: 80px;
      position: relative;
      left: -13%;
      margin-top: 3px;

      @media screen and (max-width: 860px) {
        display: none;
      }
    }

    .image-cone {
      width: 80px;
      height: 80px;
      position: relative;
      left: -3%;
      margin-top: -25px;

      @media screen and (max-width: 860px) {
        left: -14%;
      }

      @media screen and (max-width: 747px) {
        left: -34%;
      }
    }

    @media screen and (max-width: 860px) {
      position: relative;

      &-content {
        width: 60%;
        flex-shrink: 0;
        flex-grow: 0;
      }

      & .image-torus,
      & .image-cone {
        position: absolute;
        left: auto;
        top: auto;
      }

      & .image-torus {
        right: 10%;
        bottom: 0;
        width: 170px;
        height: 174px;
        overflow: hidden;
      }

      & .image-cone {
        right: 10%;
        top: -30px;
        margin-top: 0;
      }
    }

    @media screen and (max-width: 660px) {
      position: relative;

      &-content {
        width: 70%;
      }

      & .image-torus {
        right: 4%;
      }

      & .image-cone {
        right: 4%;
      }
    }

    @include respond("sm") {
      &-content {
        position: relative;
        z-index: 5;
        padding: 20px 0;
      }

      & .image-torus {
        width: 90px;
        height: 85px;
      }

      & .image-torus img {
        width: 90px;
      }
    }
  }

  .estimation {
    h2 {
      font-size: 40px;
      font-weight: 800;
      line-height: 56px;
      letter-spacing: 1px;
      color: #0b1524;
      padding-top: 90px;
      margin-bottom: 38px;

      @include respond("md") {
        padding-top: 40px;
        margin-bottom: 20px;

        font-size: 32px;
        line-height: 40px;
        text-align: center;
      }

      @include respond("sm") {
        margin-bottom: 15px;

        font-size: 24px;
        line-height: 36px;
      }
    }

    .selector-responsive {
      position: relative;

      &-block {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 0 95px;

        @include respond("lg") {
          display: block;
          padding: 0;
        }

        &-slider {
          height: 140px;

          @include respond("lg") {
            height: auto;
            margin-bottom: 32px;
          }

          .slick-arrow {
            @include carouselArrowBtn;

            @include respond("lg") {
              top: 50%;
            }

            &.slick-prev {
              @include respond("lg") {
                left: 4px;
              }
            }

            &.slick-next {
              @include respond("lg") {
                right: 4px;
              }
            }
          }
        }
      }

      &-line {
        position: relative;
        background: rgba(11, 21, 36, 0.2);
        mix-blend-mode: normal;
        width: 100%;
        height: 1px;
        margin-bottom: 50px;

        @include respond("lg") {
          margin-bottom: 28px;

          .selector-icon-wrap,
          .selector-icon-wrap img {
            width: 10px;
            height: 10px;
          }

          .selector-icon-wrap__active img {
            width: 13px;
            height: 13px;
          }

          .selector-icon-wrap__active {
            width: 24px;
            height: 24px;
            box-shadow: none;
            min-width: 0;
          }
        }
      }

      &-icons {
        position: absolute;
        width: 100%;
        display: flex;
        justify-content: space-between;
        top: -12px;

        .selector-step {
          @include respond("md") {
            &:first-child {
              transform: translateX(-8px);
            }

            &:last-child {
              transform: translateX(8px);
            }
          }
        }
      }
    }

    .selector {
      position: relative;

      @include respond("lg") {
        & .slick-track {
          display: flex;
        }

        & .slick-slide,
        & .slick-slide > div,
        & .slick-slider .selector-step {
          height: auto !important;
          display: flex !important;
          align-items: stretch;
          flex-grow: 1;
        }
      }

      &-line {
        position: absolute;
        background: #0b1524;
        mix-blend-mode: normal;
        opacity: 0.2;
        width: 1px;
        min-height: 240px;
        left: 19px;
        margin-top: 26px;

        @include respond("lg") {
          display: none;
        }
      }

      &-icon-wrap {
        height: 40px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 24px;
        box-shadow: none;
        background: transparent;
        min-width: 40px;
        position: relative;

        @include respond("lg") {
          margin: 0;
          min-width: auto;
        }

        &__active {
          @extend .selector-icon-wrap;
          min-width: 40px;
          background: #ebf1fb;
          box-shadow: 0 0 8px rgba(51, 51, 51, 0.0991313);
          animation: Opacity 200ms ease-in;

          @keyframes Opacity {
            0% {
              opacity: 0.25;
            }
            50% {
              opacity: 0.5;
            }
            100% {
              opacity: 1;
            }
          }

          .active-icon {
            max-width: 16px;

            @include respond("lg") {
              max-width: 13px !important;
              width: auto !important;
            }
          }
        }
      }

      &-steps {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: absolute;
        height: 344px;

        @include respond("lg") {
          display: none;
        }
      }

      &-step {
        display: flex;
        align-items: center;
        max-width: 400px;
        cursor: pointer;
        margin-bottom: 76px;

        @include respond("lg") {
          max-width: 100%;
          margin-bottom: 0;
          width: 24px;
          justify-content: center;
        }

        &:hover {
          .selector-icon-wrap {
            img {
              filter: invert(57%) sepia(71%) saturate(5328%) hue-rotate(201deg) brightness(88%) contrast(90%);
            }
          }

          p:not(.step__active) {
            color: #3574d6;
            opacity: 1;
          }
        }

        p {
          font-size: 18px;
          font-weight: 600;
          line-height: 150%;
          letter-spacing: 0.5px;
          opacity: 0.2;
          transition: all 200ms ease-out;

          @include respond("lg") {
            flex-grow: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            background: rgba(235, 241, 251, 0.5);
            border-radius: 20px;
            padding: 6px 50px;

            text-align: center;
          }
        }

        p.step__active {
          cursor: default;
          opacity: 1;
          transition: all 200ms ease-in;
        }

        > img {
          margin-right: 24px;
        }
      }

      &-list {
        display: flex;
        margin-left: auto;
        padding-left: 420px;
        max-width: 1220px;
        width: 100%;
        flex-direction: column;
        margin-bottom: 28px;
        min-height: 424px;

        @include respond("lg") {
          min-height: auto;
          max-width: 100%;
          padding-left: 0;
          margin-top: 24px;
        }

        @include respond("sm") {
          text-align: center;
        }

        li {
          margin-bottom: 20px;
          font-size: 18px;
          line-height: 32px;
          list-style: disc;
          animation: Opacity 200ms ease-in;

          @include respond("lg") {
            list-style: none;
          }
        }
      }

      &-button-wrap {
        //display: flex;
        display: none;
        margin-left: auto;
        max-width: 840px;
        width: 100%;
        flex-direction: column;

        > h3 {
          font-size: 24px;
          line-height: 48px;
          margin-bottom: 32px;

          @include respond("lg") {
            font-size: 18px;
            line-height: 24px;
          }
        }

        a {
          color: #3574d6;
          font-weight: 600;
          text-decoration: none;

          ::after {
            content: "";
            width: 76px;
            height: 4px;
            display: block;
            border-radius: 20px;
            position: relative;
            top: 8px;
            padding: 0 15px;
            background-color: #3574d6;
          }
        }
      }
    }
  }
}
