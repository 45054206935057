@import "../../../../assets/scss/index";

.n-clients-about {
  @include respond("lg") {
    padding: 0 0 60px;

    &__title {
      position: static;
      margin-bottom: 40px;

      font-size: 30px;
    }

    &__slider {
      position: relative;

      &::before {
        top: 65px;
      }
    }

    &__slider-main {
      padding-top: 0;
      padding-bottom: 80px;
      width: 40%;
      flex-grow: 1;
      max-width: 570px;
      min-width: 200px;
    }

    &__slider-title {
      font-size: 18px;
    }
  }

  @include respond("md") {
    padding: 0 0 40px;

    &__title {
      margin-bottom: 18px;

      font-size: 24px;
    }

    &__slider-wrap {
      position: relative;

      &::before {
        top: 48vw;
        left: -8px;
        width: 80px;
        height: 64px;
        background-size: contain;
      }
    }

    &__slider-content {
      flex-direction: column;
    }

    &__slider-main {
      width: 100%;
      padding: 0;
      max-width: none;
    }

    &__slider-client {
      padding-top: 20px;
    }

    &__slider-video {
      order: -1;
      width: calc(100% - 60px);
      align-self: center;
      margin: 0 30px 22px;
      flex-grow: 0;
      justify-content: flex-start;
    }

    &__slider-nav {
      top: 0;
      left: 0;
      bottom: auto;
      width: 100%;
      justify-content: space-between;

      & > div {
        justify-content: space-between;
        width: 100%;
      }
    }

    &__slider-arrow {
      width: 40px;
      height: 40px;
      transform: translateY(-50%);
      margin-top: 25%;

      &._prev {
        padding-right: 10px;
      }

      &._next {
        padding-left: 10px;
      }
    }
  }

  @include respond("sm") {
    &__slider-arrow {
      margin-top: 24.5%;
    }
  }

  @media screen and (max-width: 400px) {
    &__slider-arrow {
      margin-top: 22.5%;
    }
  }
}
