.play_button {
  background-color: #ffffff;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;

  &:before {
    display: block;
    content: "";
    border: 0px solid transparent;
    border-bottom: 15px solid transparent;
    border-top: 15px solid transparent;
    border-left: 20px solid #2273ce;
    margin: 0 0 0 5px;
  }

  &--secondary {
    background-color: rgba($color: #545c73, $alpha: 0.68);
    transition: all 0.2s ease-in;

    &:hover {
      background-color: rgba($color: #545c73, $alpha: 0.9);
    }
    &:before {
      border: 0px solid transparent;
      border-bottom: 15px solid transparent;
      border-top: 15px solid transparent;
      border-left: 20px solid #ffffff;
    }
  }
}
