@import "../../assets/scss/index";

.homepage {
  width: 100%;
  margin: 0 auto;
  max-width: 100%;
  position: relative;
  overflow: hidden;

  section.first-section {
    display: flex;
    position: relative;
    min-height: 95vh;
    width: 100%;
    flex-wrap: wrap;

    @media (max-width: 1370px) {
      min-height: 75vh;
    }

    @media (max-width: 1023px) {
      margin-bottom: 0;
    }

    @include respond("md") {
      margin: 0;
      min-height: 0;
    }

    #rendererContainer {
      position: absolute;
      top: 0;
      z-index: 1;
      left: 0;
      bottom: 0;
      right: 0;
      background-size: 100%;
      overflow: hidden;
    }

    .top-section {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;
      margin-bottom: 36px;

      @media screen and (max-width: 1023px) {
        flex-direction: column;
        justify-content: flex-start;
        margin-bottom: 0;
      }

      .first-section-image {
        @media screen and (max-width: 1023px) {
          margin-top: 50px;
          margin-bottom: 50px;
        }

        > img {
          @media screen and (max-width: 1470px) {
            width: 700px;
          }
          @media screen and (max-width: 1370px) {
            width: 550px;
          }
          @media screen and (max-width: 1220px) {
            width: 450px;
          }

          @media screen and (max-width: 767px) {
            width: 400px;
          }

          @media screen and (max-width: 500px) {
            width: 300px;
          }
        }
      }
    }

    .centered-section {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      z-index: 2;
      max-width: 600px;

      h1 {
        font-family: Montserrat;
        font-size: 55px;
        font-weight: 800;
        margin-bottom: 24px;
        line-height: 1.2;
        letter-spacing: 2px;

        @media screen and (max-width: 1200px) {
          font-size: 50px;
        }

        @media screen and (max-width: 1060px) {
          margin-top: 45px;
          font-size: 45px;
        }

        @media screen and (max-width: 720px) {
          font-size: 40px;
        }

        @media screen and (max-width: 480px) {
          font-size: 36px;
          margin-top: 45px;
          line-height: 45px;
        }
      }

      .description {
        font-family: Montserrat;
        font-size: 24px;
        margin-bottom: 48px;
        letter-spacing: 0.5px;

        @include respond("lg") {
          line-height: 32px;
        }

        @media screen and (max-width: 480px) {
          font-size: 14px;
          line-height: 24px;
          margin-bottom: 20px;
        }
      }

      .buttons-wrapper {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        width: 100%;

        @include respond("sm") {
          justify-content: space-between;
          flex-wrap: nowrap;
        }

        .buttons-first-section {
          display: flex;
          align-items: center;
          justify-content: center;
          min-height: 40px;
          min-width: 210px;
          background-color: #3474d6;
          border: 0 solid transparent;
          border-radius: 75px;
          font-family: Montserrat;
          font-size: 14px;
          font-weight: 600;
          letter-spacing: 2px;
          color: #fff;
          text-decoration: none;
          transition: all 0.3s ease-in;
          cursor: pointer;

          &.youtubeLinkBtn {
            background: $menu-icon-bg;
            color: $blue;
          }

          @include respond("sm") {
            min-width: 160px;
          }

          @media screen and (max-width: 426px) {
            min-width: 140px;
            font-size: 12px;
            letter-spacing: 0px;

            &.youtubeLinkBtn {
              margin-left: 0 !important;
            }
          }

          &:hover {
            background-color: #fff;
            box-shadow: 0 0 8px rgba(51, 51, 51, 0.0991313);
            color: #3574d6;
          }

          &:first-child {
            margin-right: 15px;
          }

          &:last-child {
            margin-left: 15px;
          }
        }
      }
    }

    &-image {
      position: absolute;
      background-color: rebeccapurple;
    }
  }

  .second-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #0b1524;
    padding: 90px 0;

    @media screen and (max-width: 768px) {
      padding: 30px 0;
    }

    h3.title {
      font-family: Montserrat;
      font-size: 48px;
      font-weight: 800;
      text-align: center;
      text-transform: capitalize;
      color: #fff;
      margin-bottom: 110px;

      @media screen and (max-width: 768px) {
        font-size: 30px;
        margin: 50px 0 50px;
      }
    }

    .showMoreLink {
      display: block;
      position: relative;
      font-family: Montserrat;
      font-size: 13px;
      font-weight: bold;
      letter-spacing: 2px;
      color: #fff;
      padding: 7px 0;
      margin-top: 65px;
      text-decoration: none;
      cursor: pointer;

      &::after {
        content: "";
        display: block;
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        height: 2px;
        background: #3574d6;
        transition: all 0.3s;
      }

      &:hover::after {
        right: 100%;
      }
    }
  }

  .third-section {
    display: flex;
    position: relative;
    z-index: 1;
  }

  .fourth-section {
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 768px) {
      margin: 30px 0;
    }

    h3.title {
      text-align: center;
      font-size: 48px;
      margin: 45px 0 80px;
      text-transform: capitalize;
      color: #333333;
      font-family: Montserrat;
      font-weight: 800;

      @media screen and (max-width: 768px) {
        font-size: 30px;
        margin: 50px 0 50px;
      }
    }
  }

  &__proud,
  &__cases {
    margin-bottom: 70px;
  }

  @include respond("lg") {
    &__proud,
    &__cases {
      margin-bottom: 60px;
    }
  }

  @include respond("md") {
    &__proud,
    &__cases {
      margin-bottom: 40px;
    }
  }
}
