@import "../../../../assets/scss/index";

.n-partners {
  width: 100%;
  background: rgba(235, 241, 251, 0.3);
  padding: 48px 0;

  &-block {
    display: flex;
    justify-content: center;
  }

  &-header {
    width: 260px;
    font-size: 32px;
    font-weight: 800;
    line-height: 48px;
    letter-spacing: 1px;
    display: flex;
    align-items: center;
  }

  &-list {
    display: flex;
    justify-content: space-around;
    padding-left: 30px;
    flex-grow: 1;
  }

  & .slick-arrow {
    @include carouselArrowBtn;

    &.slick-prev {
      left: -12px;
    }

    &.slick-next {
      right: -12px;
    }
  }

  & .slick-dots {
    @include carouselDots;
  }

  & .slick-track {
    display: flex;
    align-items: center;
  }

  & .slick-slide > div {
  }

  &-partner-wrap {
    padding: 0 10px;

    & a {
      text-decoration: none;
      color: #3574d6;
      font-weight: 500;
      font-size: 16px;
    }

    & img {
      margin: 0 auto;
      max-width: 100%;
      height: 75px;
      object-fit: contain;
      transition: 0.3s linear all;

      &:hover {
        transform: scale(1.3333);
        filter: drop-shadow(0px 10px 10px rgba(0, 0, 0, 0.25));

        @include respond("lg") {
          filter: unset;
          transform: scale(1);
        }
      }
    }

    & img.with-caption {
      height: 50px;
    }
  }
}

//ADAPTIVE

.n-partners {
  @include respond("lg") {
    &-block {
      display: block;
    }

    &-header {
      width: 100%;
      justify-content: center;
      padding-bottom: 10px;
      font-size: 28px;
      text-align: center;
    }

    &-list {
      display: block;
      padding: 0;
      flex-wrap: wrap;
    }

    & .slick-slide {
    }

    & .slick-slide > div {
      display: flex;
      justify-content: space-between;
    }
  }

  @include respond("md") {
    padding: 24px 0 38px;

    &-header {
      padding: 0;
      margin-bottom: 20px;

      font-size: 28px;
      line-height: 1.3em;
    }

    & .slick-arrow.slick-prev {
      left: -16px;
    }

    & .slick-arrow.slick-next {
      right: -16px;
    }

    &-partner-wrap img {
      height: 55px;
    }
  }

  @include respond("sm") {
    &-header {
      margin-bottom: 10px;

      font-size: 20px;
    }

    &-partner-wrap img {
      height: 75px;
    }
  }
}
