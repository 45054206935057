@import "../../../../assets/scss/index";

.n-clients-about {
  position: relative;
  padding: 0 0 70px;

  &__title {
    max-width: 700px;
    pointer-events: none;

    font-size: 40px;
    font-weight: 800;
    line-height: 45px;
    letter-spacing: 1px;
    margin-bottom: 15px;
  }

  &__slider {
    position: relative;
  }

  &__slider-wrap {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 86px;
      left: -23px;
      width: 120px;
      height: 96px;
      background: url("../../../../assets/images/Partners/quotes.svg") no-repeat center;
    }

    & .slick-track {
      display: flex;
    }

    & .slick-slide {
      flex-shrink: 0;
      float: none;
      height: auto;
      display: flex;
    }

    & .slick-slide > div {
      flex-grow: 1;
      display: flex;
    }
  }

  &__slider-content {
    position: relative;
    display: flex !important;
    justify-content: space-between;
    font-size: 24px;
    line-height: 48px;
    margin-left: -2px;
    padding-left: 2px;
  }

  &__slider-nav {
    position: absolute;
    z-index: 5;
    left: -5px;
    bottom: 0;
    display: flex;
    pointer-events: none;

    & > div {
      display: flex;
    }
  }

  &__slider-arrow {
    @include reset-button;
    pointer-events: auto;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;

    &._prev {
      margin-right: 29px;
    }

    & svg {
      width: 18px;
      height: 12px;
      fill: #858a91;

      transition: $time;
    }

    &._prev svg {
      transform: rotate(90deg);
    }

    &._next svg {
      transform: rotate(-90deg);
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover svg {
        fill: $blue;
      }
    }
  }

  &__slider-main {
    padding-top: 106px;
    padding-bottom: 80px;
    width: 40%;
    flex-grow: 1;
    max-width: 570px;
    min-width: 370px;
  }

  &__slider-title {
    font-weight: 400;
    font-size: 24px;
    line-height: 1.67em;
    letter-spacing: 0.5px;
  }

  &__slider-client {
    padding-top: 40px;

    font-size: 16px;
    line-height: 18px;
    font-weight: 400;
  }

  &__slider-client-name {
    margin-bottom: 10px;

    font-weight: 600;
  }

  &__slider-client-position {
    margin-bottom: 12px;
  }

  &__slider-client-location {
    display: flex;
    align-items: center;

    color: $blue;

    & img {
      margin-right: 5px;
      flex-shrink: 0;
    }
  }

  &__slider-video {
    flex-grow: 1;
    width: 60%;
    max-width: 810px;
    margin-left: 30px;
    display: flex;
    background: #ebf1fb;
    border-radius: 10px;
    overflow: hidden;

    & .video_player {
      position: relative;
      padding-top: 56.224%;
      width: 100%;
    }

    & .video_player > div {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
