@import "../../../../assets/scss/index";

@keyframes gradient-animation {
  0%,
  5% {
    width: 580px;
    height: 580px;
    border-radius: 50%;
    transform: rotate(0deg);
  }
  18%,
  39% {
    height: 580px;
    width: 400px;
    border-radius: 0%;
    transform: rotate(0deg);
  }
  51%,
  72% {
    width: 580px;
    border-radius: 0%;
    transform: rotate(30deg);
  }
  85%,
  100% {
    width: 580px;
    height: 580px;
    border-radius: 50%;
    transform: rotate(0deg);
  }
}

@keyframes figure-cube-animation {
  0%,
  12% {
    right: 250px;
    bottom: -20px;
  }
  18%,
  45% {
    right: 750px;
    bottom: 550px;
  }
  51%,
  79% {
    right: 0px;
    bottom: 330px;
  }
  85%,
  100% {
    right: 250px;
    bottom: -20px;
  }
}

@keyframes figure-donut-animation {
  0%,
  12% {
    right: 50px;
    bottom: 400px;
    transform: rotate(90deg);
  }
  18%,
  45% {
    right: 565px;
    bottom: 30px;
    transform: rotate(350deg);
  }
  51%,
  79% {
    right: 170px;
    bottom: 0px;
    transform: rotate(260deg);
  }
  85%,
  100% {
    right: 50px;
    bottom: 400px;
    transform: rotate(90deg);
  }
}

@keyframes figure-semicircle-animation {
  0%,
  12% {
    right: 550px;
    bottom: 0px;
    transform: rotate(30deg);
  }
  18%,
  45% {
    right: 20px;
    bottom: 350px;
    transform: rotate(361deg);
  }
  51%,
  79% {
    right: 600px;
    bottom: 500px;
    transform: rotate(0deg);
  }
  85%,
  100% {
    right: 550px;
    bottom: 0px;
    transform: rotate(30deg);
  }
}

@keyframes tooltip-week-animation {
  0%,
  12% {
    width: 330px;
    height: 150px;
    transform: translate(0, 0);
    opacity: 1;
  }
  25%,
  75% {
    width: 0px;
    height: 0px;
    opacity: 0;
    transform: translateY(-150px);
  }
  75%,
  81% {
    width: 0px;
    height: 0px;
    opacity: 0;
    transform: translate(0, 0);
  }
  88%,
  100% {
    width: 330px;
    height: 150px;
    opacity: 1;
  }
}

@keyframes tooltip-steps-animation {
  0%,
  12% {
    width: 359px;
    height: 123px;
    opacity: 1;
    transform: translate(0, 0);
  }
  16%,
  55% {
    width: 0px;
    height: 0px;
    opacity: 0;
  }
  55%,
  78% {
    width: 0px;
    height: 0px;
    opacity: 0;
    transform: translateY(150px);
  }
  85%,
  100% {
    width: 359px;
    height: 123px;
    opacity: 1;
    transform: translate(0, 0);
  }
}

@keyframes tooltip-health-animation {
  0%,
  12% {
    width: 206px;
    height: 163px;
    opacity: 1;
    transform: translate(0, 0);
  }
  20%,
  55% {
    width: 0px;
    height: 0px;
    opacity: 0;
  }
  55%,
  80% {
    width: 0px;
    height: 0px;
    opacity: 0;
    transform: translateY(200px);
  }
  85%,
  100% {
    width: 206px;
    height: 163px;
    opacity: 1;
    transform: translate(0, 0);
  }
}

@keyframes vertical-phone-animation {
  0%,
  10% {
    opacity: 1;
    transform: translate(0, 0);
  }
  15%,
  45% {
    opacity: 0;
    transform: translateY(-500px);
  }
  45%,
  90% {
    opacity: 0;
    transform: translateY(300px);
  }
  95%,
  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}

@keyframes tablet-animation {
  0%,
  17% {
    opacity: 0;
    transform: translateY(300px);
  }
  22%,
  40% {
    opacity: 1;
    transform: translate(0, 0);
  }
  45%,
  90% {
    opacity: 0;
    transform: translateY(-500px);
  }
  95%,
  100% {
    opacity: 0;
    transform: translateY(300px);
  }
}

@keyframes stylus-animation {
  0%,
  23% {
    height: 0px;
    width: 0px;
    opacity: 0;
    transform: translateY(-200px);
  }
  28%,
  37% {
    width: 335px;
    height: 61px;
    opacity: 1;
    transform: translate(0, 0);
  }
  42%,
  90% {
    height: 0px;
    width: 0px;
    opacity: 0;
    transform: translateY(200px);
  }
  95%,
  100% {
    height: 0px;
    width: 0px;
    opacity: 0;
    transform: translateY(-200px);
  }
}

@keyframes stylus-shadow-animation {
  0%,
  27% {
    opacity: 0;
  }
  28%,
  35% {
    opacity: 0.7;
  }
  42%,
  100% {
    opacity: 0;
  }
}

@keyframes angle-phone-animation {
  0%,
  26% {
    height: 0px;
    width: 0px;
    opacity: 0;
    transform: translateY(-200px);
  }
  31%,
  39% {
    width: 290.115px;
    height: 232.605px;
    opacity: 1;
    transform: translate(0, 0);
  }
  44%,
  90% {
    height: 0px;
    width: 0px;
    opacity: 0;
    transform: translateY(-200px);
  }
  95%,
  100% {
    height: 0px;
    width: 0px;
    opacity: 0;
  }
}

@keyframes angle-phone-shadow-animation {
  0%,
  29% {
    opacity: 0;
  }
  32%,
  39% {
    opacity: 0.9;
  }
  41%,
  100% {
    opacity: 0;
  }
}

@keyframes notebook-animation {
  0%,
  55% {
    opacity: 0;
    transform: translateY(300px);
  }
  60%,
  73% {
    opacity: 1;
    transform: translate(0, 0);
  }
  78%,
  90% {
    opacity: 0;
    transform: translateY(-500px);
  }
  95%,
  100% {
    opacity: 0;
    transform: translateY(300px);
  }
}

@keyframes emergency-help-animation {
  0%,
  58% {
    width: 0px;
    height: 0px;
    opacity: 0;
    transform: translateY(200px);
  }
  63%,
  77% {
    width: 268.4px;
    height: 195.2px;
    opacity: 1;
    transform: translate(0, 0);
  }
  82%,
  90% {
    width: 0px;
    height: 0px;
    opacity: 0;
    transform: translateY(200px);
  }
  95%,
  100% {
    width: 0px;
    height: 0px;
    opacity: 0;
    transform: translateY(200px);
  }
}

@keyframes symptoms-animation {
  0%,
  57% {
    width: 0px;
    height: 0px;
    opacity: 0;
    transform: translateY(100px);
  }
  62%,
  75% {
    width: 397.12px;
    height: 220.32px;
    opacity: 1;
    transform: translate(0, 0);
  }
  80%,
  90% {
    width: 0px;
    height: 0px;
    opacity: 0;
    transform: translateY(100px);
  }
  95%,
  100% {
    width: 0px;
    height: 0px;
    opacity: 0;
    transform: translateY(100px);
  }
}

.first-section-animation {
  position: relative;
  min-width: 800px;
  max-width: 800px;
  height: 740px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: -1;

  .gradient {
    position: absolute;
    width: 580px;
    height: 580px;
    border-radius: 50%;
    z-index: 2;
    background: linear-gradient(#dde8fa79, #8bb0e7b9);
    animation-name: gradient-animation;
    animation-duration: $animation-duration;
    animation-delay: 1.5s;
    animation-iteration-count: infinite;
  }

  .figure-cube {
    position: absolute;
    right: 250px;
    bottom: -20px;
    z-index: 1;
    animation-name: figure-cube-animation;
    animation-duration: $animation-duration;
    animation-delay: 0.5s;
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(0.57, 0.72, 0.49, 1.15);
  }

  .figure-donut {
    position: absolute;
    right: 50px;
    bottom: 400px;
    transform: rotate(90deg);
    z-index: 3;
    animation-name: figure-donut-animation;
    animation-duration: $animation-duration;
    animation-delay: 0.5s;
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(0.57, 0.72, 0.49, 1.15);
  }

  .figure-semicircle {
    position: absolute;
    right: 550px;
    bottom: 0px;
    transform: rotate(30deg);
    z-index: 1;
    animation-name: figure-semicircle-animation;
    animation-duration: $animation-duration;
    animation-delay: 0.5s;
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(0.57, 0.72, 0.49, 1.15);
  }

  .tooltip-week {
    position: absolute;
    width: 330px;
    height: 150px;
    margin-top: 450px;
    margin-left: 500px;
    opacity: 1;
    z-index: 4;
    animation-name: tooltip-week-animation;
    animation-duration: $animation-duration;
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(0.57, 0.72, 0.49, 1.15);
    animation-delay: 0.5s;
  }

  .tooltip-steps {
    position: absolute;
    width: 359px;
    height: 123px;
    margin-top: 340px;
    margin-right: 240px;
    opacity: 1;
    z-index: 3;
    animation-name: tooltip-steps-animation;
    animation-duration: $animation-duration;
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(0.57, 0.72, 0.49, 1.15);
    animation-delay: 0.5s;
  }

  .tooltip-health {
    position: absolute;
    width: 185px;
    height: 147px;
    margin-bottom: 240px;
    margin-right: 450px;
    opacity: 1;
    z-index: 3;
    animation-name: tooltip-health-animation;
    animation-duration: $animation-duration;
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(0.57, 0.72, 0.49, 1.15);
    animation-delay: 0.5s;
  }

  .wrapper-vertical-phone {
    display: flex;
    justify-content: center;
    width: 580px;
    height: 800px;
    margin-bottom: 220px;
    overflow: hidden;
    z-index: 3;
    border-radius: 0% 0% 50% 50% / 0% 0% 35% 35%;
    border: 1px solid transparent;

    .vertical-phone {
      width: 364px;
      height: 735px;
      margin-top: 200px;
      animation-name: vertical-phone-animation;
      animation-duration: $animation-duration;
      animation-iteration-count: infinite;
      animation-delay: 0.5s;
      animation-timing-function: ease;
    }
  }

  .wrapper-tablet {
    position: absolute;
    z-index: 3;
    height: 800px;
    margin-bottom: 220px;
    overflow: hidden;

    .tablet {
      margin-top: 300px;
      height: 530px;
      opacity: 0;
      transform: translateY(300px);
      animation-name: tablet-animation;
      animation-duration: $animation-duration;
      animation-iteration-count: infinite;
      animation-delay: 0.5s;
      animation-timing-function: ease;
    }
  }

  .stylus {
    position: absolute;
    width: 0px;
    height: 0px;
    opacity: 0;
    margin-top: 105px;
    margin-left: 357px;
    z-index: 3;
    animation-name: stylus-animation;
    animation-duration: $animation-duration;
    animation-iteration-count: infinite;
    animation-delay: 0.5s;
    animation-timing-function: ease;
  }

  .stylus-shadow {
    position: absolute;
    top: 436px;
    left: 447px;
    width: 241px;
    height: 228px;
    opacity: 0;
    z-index: 3;
    animation-name: stylus-shadow-animation;
    animation-duration: $animation-duration;
    animation-iteration-count: infinite;
    animation-delay: 0.5s;
    animation-timing-function: ease;
  }

  .angle-phone {
    position: absolute;
    height: 0px;
    width: 0px;
    opacity: 0;
    margin-bottom: 350px;
    margin-left: 140px;
    z-index: 3;
    animation-name: angle-phone-animation;
    animation-duration: $animation-duration;
    animation-iteration-count: infinite;
    animation-delay: 0.5s;
    animation-timing-function: ease;
  }

  .angle-phone-shadow {
    position: absolute;
    width: 166.1px;
    height: 150.7px;
    margin-bottom: 167px;
    margin-right: 60px;
    opacity: 0;
    z-index: 3;
    animation-name: angle-phone-shadow-animation;
    animation-duration: $animation-duration;
    animation-iteration-count: infinite;
    animation-delay: 0.5s;
    animation-timing-function: ease;
  }

  .wrapper-overflow-notebook {
    position: absolute;
    z-index: 3;
    height: 1200px;
    width: 1200px;
    margin-bottom: 845px;
    margin-right: 225px;
    overflow: hidden;
    transform: rotate(-60deg);

    .wrapper-notebook {
      position: absolute;
      bottom: 100px;
      left: -50px;
      transform: rotate(60deg);

      .notebook {
        width: 710.08px;
        height: 432.32px;
        opacity: 0;
        transform: translateY(300px);
        animation-name: notebook-animation;
        animation-duration: $animation-duration;
        animation-iteration-count: infinite;
        animation-delay: 0.5s;
        animation-timing-function: ease;
      }
    }
  }

  .emergency-help {
    position: absolute;
    width: 0px;
    height: 0px;
    margin-bottom: 420px;
    margin-left: 550px;
    z-index: 3;
    opacity: 0;
    transform: translateY(200px);
    animation-name: emergency-help-animation;
    animation-duration: $animation-duration;
    animation-iteration-count: infinite;
    animation-delay: 0.5s;
    animation-timing-function: ease;
  }

  .symptoms {
    position: absolute;
    width: 0;
    height: 0;
    margin-right: 365px;
    margin-top: 460px;
    z-index: 3;
    opacity: 0;
    transform: translateY(100px);
    animation-name: symptoms-animation;
    animation-duration: $animation-duration;
    animation-iteration-count: infinite;
    animation-delay: 0.5s;
    animation-timing-function: ease;
  }
}
