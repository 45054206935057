.video_player {
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  position: relative;
  &-aspect_ration {
    width: 100%;
  }

  &--aspect_ratio {
    > div:first-of-type {
      width: 100%;
      top: 0;
      position: absolute;
    }
  }
  .play_button {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
