@import "../../assets/scss/index.scss";

.blog-articles-section {
  @include respond("lg") {
    &__title {
      font-size: 32px;
    }

    &__item {
      width: calc((100% - 20px) / 2);
      margin-right: 20px;
      margin-top: 30px;

      &:nth-child(3) {
        margin-right: 20px;
        margin-top: 30px;
      }

      &:nth-child(1),
      &:nth-child(2) {
        margin-top: 0;
      }

      &:nth-child(2n) {
        margin-right: 0;
      }
    }
  }

  @include respond("md") {
    display: flex;
    flex-direction: column;

    &__head {
      justify-content: center;
      margin-bottom: 20px;
    }

    &__title {
      font-size: 24px;
      line-height: 1.5em;
    }

    &__more {
      &._desc {
        display: none;
      }

      &._mob {
        display: inline-flex;
      }

      align-self: center;
      margin-top: 32px;

      font-size: 12px;
    }
  }

  @include respond("sm") {
    &__item {
      width: 100%;
      margin-right: 0 !important;
      margin-top: 30px !important;

      &:first-child {
        margin-top: 0 !important;
      }
    }
  }
}
