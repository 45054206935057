@import "../../../../assets/scss/index";

.n-cards {
  width: 100%;
  background: rgba(235, 241, 251, 0.3);
  padding: 28px 0;

  & .slick-track {
    display: flex;
  }

  &-card {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-right: 4.4vw;

    & a {
      text-decoration: none;
      color: #0b1524;
    }

    & img {
      margin-bottom: 16px;
    }
  }

  &-card-label {
    margin: 0;

    font-size: 18px;
    line-height: 32px;
    font-weight: 400;
    font-style: normal;
    color: #0b1524;
    white-space: nowrap;
  }

  & .slick-arrow {
    @include carouselArrowBtn;

    &.slick-prev {
      left: -12px;
    }

    &.slick-next {
      right: -12px;
    }
  }

  & .slick-dots {
    @include carouselDots;
  }
}

.n-cards {
  @media screen and (max-width: 1280px) {
    &-card-label {
      font-size: 16px;
      line-height: 1.4em;
    }

    & img {
      margin-bottom: 8px;
    }
  }

  @include respond("lg") {
    padding-bottom: 38px;
  }

  @include respond("md") {
    padding: 24px 0 38px;

    &-card {
      padding-right: 10px;
      padding-left: 10px;
    }

    & img {
      margin: 0 auto;
    }

    &-card-label {
      text-align: center;
      font-size: 14px;
    }
  }
}
