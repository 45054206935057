$blue: #3574d6;
$red: #ff2e00;
$hover-blue: #1255be;
$blue-50: #123754;
$blue-100: #0b1524;
$white: #ffffff;
$grey: #eeeeee;

$c: $blue-100;
$c--a: $blue;
$c--alt: $white;
$bg: $white;
$bg--alt: $blue-100;
$primary-bg: $blue;
$menu-icon-bg: #ebf1fb;
$primary-c: $white;
$secondary-bg: $white;
$secondary-c: $blue;

$rectangle-gradient: linear-gradient(300deg, #a6c5f6, #eeeeee);

$tablet-bp: "max-width:768px";
$mobile-bp: "max-width:768px";

$animation-duration: 9s;

$page-max-width: 1408px;
$page-max-width-small: 1110px;
$page-padding: 32px;
$page-padding-lg: 24px;
$page-padding-md: 20px;
$page-padding-sm: 16px;

$time: 0.15s;
$radius: 10px;
$radius-large: 20px;
