$breakpoints: (
  "sm": 550px,
  "md": 768px,
  "lg": 1024px,
  "xl": 1440px,
) !default;

@mixin respond($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media (max-width: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  } @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}

@mixin reset-button {
  background: transparent;
  border: 0;
  padding: 0;
  box-shadow: none;
  border-radius: 0;
  text-decoration: none;

  font: inherit;
  color: inherit;
  line-height: inherit;
}

@mixin h1 {
  font-size: 50px;
  line-height: 1.5em;
  font-weight: 800;
  letter-spacing: 2px;
}

@mixin h2 {
  font-size: 40px;
  line-height: 1.5em;
  font-weight: 800;
  letter-spacing: 1px;

  @include respond("md") {
    font-size: 30px;
  }
}

@mixin h3 {
  font-size: 32px;
  line-height: 1.5em;
  font-weight: 800;
  letter-spacing: 1px;

  @include respond("md") {
    font-size: 24px;
  }
}

@mixin h4 {
  font-size: 24px;
  line-height: 1.5em;
  font-weight: 800;
  letter-spacing: 0.5px;

  @include respond("md") {
    font-size: 20px;
  }
}

@mixin h5 {
  font-size: 16px;
  line-height: 1.5em;
  font-weight: 600;

  @include respond("md") {
    font-size: 14px;
  }
}

@mixin h6 {
  font-size: 14px;
  line-height: 1.5em;
  font-weight: 600;

  @include respond("md") {
    font-size: 14px;
  }
}

@mixin carouselArrowBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  width: 35px;
  height: 35px;
  overflow: hidden;
  background: transparent;
  border: 0;
  transform: translateY(-50%);
  transition: 0.2s;
  opacity: 1;
  z-index: 1;

  &:before {
    display: none;
  }

  &:after {
    content: "";
    width: 20px;
    height: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-image: url("../../assets/images/Partners/arrow-next.svg");
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
  }

  &.slick-prev::after {
    transform: translate(-50%, -50%) rotate(180deg);
  }
}

@mixin carouselDots {
  font-size: 0;
  line-height: 0;

  li {
    display: inline-flex;
    margin: 0;
    width: 10px;

    &:last-child {
      margin-right: 0;
    }
  }

  & li.slick-active {
    width: 20px;
  }

  & li button {
    width: 100%;
  }

  & li button::before {
    content: "";
    width: 5px;
    height: 5px;
    top: 50%;
    left: 50%;
    border-radius: 2.5px;
    background-color: #c2c4c8;
    transform: translate(-50%, -50%);
    opacity: 1;

    transition: $time;
  }

  & li.slick-active button::before {
    opacity: 1;
    background-color: $blue;
    width: 15px;
  }
}
