@import "../../../../assets/scss/index";

.expert-services {
  margin-bottom: 32px;
  @include respond("sm") {
    margin-bottom: 16px;
  }

  &__title {
    font-size: 40px;
    line-height: 1.5em;
    font-weight: 800;
    letter-spacing: 1px;
    max-width: 690px;

    &:not(:last-child) {
      margin-bottom: 32px;

      @include respond("sm") {
        margin-bottom: 16px;
      }
    }

    @include respond("sm") {
      font-size: 24px;
      line-height: 30px;
      text-align: center;
    }
  }

  &__sub-title {
    font-size: 24px;
    line-height: 167%;
    letter-spacing: 0.5px;
    @include respond("sm") {
      font-size: 20px;
      line-height: 30px;
      text-align: center;
    }

    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }

  &__body {
    display: grid;
    gap: 30px;

    @media (min-width: 992px) {
      grid-template-columns: 1fr 1fr;
    }
  }
  &__text {
    font-size: 18px;
    line-height: 32px;
    font-weight: 400;
  }

  &__link {
    text-decoration: none;
    color: #3574d6;
    font-weight: bold;
    white-space: nowrap;

    @media (any-hover: hover) {
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
