@import "../../../../assets/scss/index";

.n-proud {
  padding-top: 70px;

  @include respond("sm") {
    padding-top: 15px;
  }

  h2 {
    font-size: 40px;
    font-weight: 800;
    line-height: 56px;
    letter-spacing: 1px;
    margin-bottom: 30px;

    @include respond("sm") {
      font-size: 27px;
      line-height: 40px;
    }
  }

  &-content {
    display: flex;
    width: 100%;
    justify-content: space-between;

    @include respond("lg") {
      flex-wrap: wrap;
    }

    @media (max-width: 350px) {
      h1 {
        font-size: 24px;
        margin-bottom: 20px;
      }
    }

    &-left {
      max-width: 810px;
      margin-top: 50px;

      @include respond("lg") {
        margin-inline: auto;
        margin-bottom: 80px;
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      @include respond("sm") {
        margin-bottom: 48px;
      }

      p {
        font-size: 18px;
        line-height: 32px;
        font-weight: 400;

        &.last {
          margin-bottom: 60px;
        }

        @include respond("lg") {
          text-align: center;
        }

        @include respond("sm") {
          font-size: 18px;
          line-height: 24px;
          margin-bottom: 24px;
        }

        @media (max-width: 350px) {
          font-size: 14px;
          line-height: 20px;
        }
      }

      &-about {
        @include respond("sm") {
          display: flex;
          justify-content: center;
        }

        a {
          background: #3574d6;
          color: #ffffff;
          font-size: 14px;
          font-weight: 600;
          line-height: 17px;
          text-align: center;
          padding: 17px 46px;
          border-radius: 28px;
          border: none;
          cursor: pointer;
          text-decoration: none;
        }
      }
    }

    &-right {
      position: relative;
      background: rgba(235, 241, 251, 0.3);
      border-radius: 20px;
      padding: 36px 30px 23px;
      margin-right: 90px;

      @include respond("lg") {
        width: 100%;
        margin-right: 0;
      }

      @include respond("sm") {
        padding: 23px 16px 18px;
      }

      h3 {
        font-size: 24px;
        font-weight: 800;
        line-height: 40px;
        margin-bottom: 20px;
        letter-spacing: 0.5px;

        @include respond("sm") {
          font-size: 14px;
          line-height: 21px;
          margin-bottom: 16px;
        }
      }

      &-image {
        position: absolute;
        right: -43px;
        top: 149px;

        @include respond("sm") {
          width: 69px;
          right: -10px;
          top: 338px;
        }
      }

      &-list {
        &-item {
          display: flex;
          align-items: center;
          height: 32px;
          margin-bottom: 15px;

          @include respond("sm") {
            margin-bottom: 10px;
          }

          &:last-child {
            margin-bottom: 0;
          }

          a,
          p {
            font-size: 18px;
            line-height: 32px;
            margin-left: 16px;
            text-decoration: none;
            color: inherit;

            @include respond("sm") {
              font-size: 14px;
              line-height: 24px;
            }
          }
        }
      }
    }
  }
}
