@import "../../../../assets/scss/index";

.n-powerful {
  width: 100%;
  background: rgba(235, 241, 251, 0.3);
  padding: 24px 0;
  margin-bottom: 70px;

  @include respond("lg") {
    padding-bottom: 50px;
  }

  @include respond("md") {
    margin-bottom: 50px;
  }

  @include respond("sm") {
    margin-bottom: 30px;
  }

  &-block {
    display: flex;
    flex-direction: column;
    row-gap: 30px;
  }

  &-header {
    font-size: 32px;
    font-weight: 800;
    line-height: 50px;
    letter-spacing: 1px;

    @media screen and (max-width: 1300px) {
      margin-right: 20px;
    }

    @include respond("lg") {
      margin-bottom: 20px;
      width: 100%;
      margin-right: 0;

      font-size: 32px;
      line-height: 40px;
      text-align: center;
    }

    @include respond("md") {
      font-size: 23px;
      line-height: 34px;
    }

    @include respond("sm") {
      font-size: 20px;
      line-height: 30px;
    }
  }

  &-list {
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
    padding: 0 0;

    @include respond("lg") {
      display: block;
      padding: 0;
    }

    &-item {
      display: inline-flex !important;
      flex-direction: column;
      align-items: flex-start;
      margin-left: 20px;

      img {
        max-height: 100px;
        max-width: 100%;
        margin-bottom: 18px;
      }

      p {
        font-size: 18px;
        line-height: 32px;
        font-weight: 400;
        color: #0b1524;
      }

      @media screen and (max-width: 1180px) {
        img {
          margin-bottom: 8px;
        }

        p {
          font-size: 14px;
          line-height: 20px;
        }
      }

      @include respond("lg") {
        align-items: center;
        margin: 0 5px;

        img {
          margin-bottom: 8px;
        }

        p {
          text-align: center;
        }
      }
    }
  }

  & .slick-arrow {
    @include carouselArrowBtn;

    &.slick-prev {
      left: -12px;
    }

    &.slick-next {
      right: -12px;
    }
  }

  .slick-dots {
    bottom: -30px;

    @include carouselDots;
  }

  .slick-list {
    .slick-track {
      display: flex;

      .slick-slide {
        margin: 0 10px;
      }
    }
  }

  @include respond("md") {
    & .slick-arrow.slick-prev {
      left: -16px;
    }

    & .slick-arrow.slick-next {
      right: -16px;
    }
  }
}
